import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Toolbar,
  Typography,
  Tooltip,
  IconButton,
  Popover,
  Grid,
} from '@material-ui/core';
import { Search } from '@material-ui/icons';
import { green, purple } from '@material-ui/core/colors';
import { HelpCircle } from 'react-feather';
import {
  SelectBase,
  TextBase,
  SwitchBase,
} from 'components/Controls';
import DateTimePickerBase from 'components/Pickers/DateTimePickerBase';
import AutocompleteBase from 'components/Autocomplete/AutocompleteBase';
import {
  Formik,
  Form,
  Field,
} from 'formik';
import GeneralPopover from 'components/Popover/GeneralPopover';
import SendExcelMailButton from 'components/Controls/SendExcelMailButton';
import { DatePickerBase, TimePickerBase, YearPickerBase } from 'components/Pickers';
import Loader from '../LoadingScreen';
import ButtonGeneral from '../Controls/ButtonGeneral';
import CreateButton from '../Controls/CreateButton';
import DownloadExcelButton from '../Controls/DownloadExcelButton';
import DownloadPdfButton from '../Controls/DownloadPdfButton';
import GeneralSearch from './GeneralSearch';
import { default as GeneralHelpCircle } from 'components/Popover/HelpCircle';

const styles = {
  title: {
    flex: 1,
  },
  popover: {
    // width: 320,
    minWidth: 100,
  },
};

const KEY_ENTER = 13;

function ToolBar({
  title,
  columns,
  filters,
  setFilter,
  onCreate,
  createPermissions,
  filtersTable,
  ExtraToolBar,
  dates,
  onDownloadExcel,
  onDownloadPdf,
  disableCreateButton,
  maxWidthFilter,
  setGenericSearch,
  titleHelpMessage,
  onSendExcel,
  genericSearch,
  updatePermissions,
  sendEmailExcelPermissions,
}) {
  const formik = useRef();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [element, setElement] = React.useState(null);
  const [openHelp, setOpenHelp] = React.useState(false);
  const [search, setSearch] = React.useState(filters);
  const [query, setQuery] = React.useState('');
  const [initialValues, setInitialValues] = React.useState({});
  const [loading, setLoading] = React.useState(true);
  const show = Boolean(anchorEl);
  const onClick = event => setAnchorEl(event.currentTarget);

  const onClose = () => setAnchorEl(null);

  /**
   * @name handleQueryChange
   * @desc Cambia lo que se busca en el input general
   * @param event
   */
  const handleQueryChange = (event) => {
    event.persist();
    setQuery(event.target.value);
  };

  /**
   * @name handleQueryChange
   * @desc Metodo que crea un array para enviar a serverside de la tabla y
   * hacer consulta para traer datos filtrados
   * @param event
   */
  const handleQueryChangeKeyUp = (event) => {
    if (event.keyCode === KEY_ENTER) {
      event.persist();
      const { target: { value } } = event;
      setGenericSearch(value);
    }
  };

  const openPopover = (event) => {
    setOpenHelp(true);
    setElement(event.currentTarget);
  };

  const closePopover = () => {
    setOpenHelp(false);
    setElement(null);
  };

  const getInitialValues = (cols) => {
    const initValues = {};

    cols.forEach((item) => {
      if (item.type === 'date-time' || item.type === 'date' || item.type === 'time') {
        initValues[item.name] = null;
      } else {
        initValues[item.name] = '';
      }
    });

    setInitialValues(initValues);
    setLoading(false);
  };

  useEffect(() => {
    getInitialValues(columns);

    // eslint-disable-next-line
  }, []);

  const onOptionSelected = ({ label = '' } = {}, { values, setValues }, colName) => {
    setValues({
      ...values,
      [colName]: label,
    });
  };

  const getType = (label, name, type, extra = {}) => {
    const {
      url = '',
      requestParams = {},
    } = extra || {};

    switch (type) {
      case 'date-time':
        return (
          <Field
            label={label}
            name={name}
            component={DateTimePickerBase}
            disablePast={false}
          />
        );
      case 'date':
        return (
          <Field
            label={label}
            name={name}
            component={DatePickerBase}
            disablePast={false}
          />
        );
      case 'time':
        return (
          <Field
            label={label}
            name={name}
            component={TimePickerBase}
            ampm={false}
          />
        );
      case 'autocomplete':
        return (
          <Field
            label={label}
            name={name}
            url={url}
            requestParams={requestParams}
            component={AutocompleteBase}
            labelAsValue
            onOptionSelected={(resp, subProps) => onOptionSelected(resp, subProps, name)}
          />
        );
      case 'status':
        return (
          <Field
            label={label}
            name={name}
            component={SelectBase}
            items={[
              { value: 'INICIAL', label: 'INICIAL' },
              { value: 'PENDIENTE', label: 'PENDIENTE' },
              { value: 'EN_PROCESO', label: 'EN PROCESO' },
              { value: 'APROBADO', label: 'APROBADO' },
              { value: 'TERMINADO', label: 'TERMINADO' },
              { value: 'ANULADO', label: 'ANULADO' },
              { value: 'CANCELADO_CLIENTE', label: 'CANCELADO CLIENTE' },
              { value: 'CANCELADO_EMPRESA', label: 'CANCELADO EMPRESA' },
            ]}
          />
        );
      case 'year':
        return (
          <Field
            label={label}
            name={name}
            component={YearPickerBase}
            ampm={false}
          />);
      case 'check':
        return (
          <Field
            label={label}
            name={name}
            component={SwitchBase}
          />
        );
      default:
        return (
          <Field
            label={label}
            name={name}
            component={TextBase}
          />
        );
    }
  };

  const handleSubmitFilter = (values) => {
    const noEmpty = {};
    // eslint-disable-next-line
    for (const prop in values) {
      const value = values[prop];
      if (value !== '' && value !== 0 && value !== null) {
        noEmpty[prop] = value;
      }
    }
    setFilter(noEmpty);
  };

  return (
    <>
      <GeneralPopover open={openHelp} anchorEl={element} onClose={closePopover}>
        <Typography style={{ padding: 3 }}>
          {titleHelpMessage}
        </Typography>
      </GeneralPopover>
      <Grid container direction="row" justify="flex-start">
        <Grid item style={{ paddingBottom: 25, display: 'flex', alignItems: 'center' }}>
          <Typography style={styles.title} variant="h6" id="tableTitle" component="div">
            {title || ''}
          </Typography>
          {titleHelpMessage && (
            <HelpCircle
              style={{ marginLeft: 15 }}
              onMouseEnter={e => openPopover(e)}
              onMouseLeave={closePopover}
              onMouseDown={e => openPopover(e)}
            />
          )}
        </Grid>
      </Grid>
      <Toolbar>
        <Grid
          container
          direction="row"
          style={{ paddingBottom: 20 }}
          justify={genericSearch ? 'space-between' : 'flex-end'}
        >
          <Grid item xs={12} md={12} xl={12} />
          {genericSearch && (
            <Grid item>
              <GeneralSearch
                value={query}
                onChange={handleQueryChange}
                onKeyUp={handleQueryChangeKeyUp}
              />
            </Grid>
          )}
          <Grid item style={{ display: 'flex', alignItems: 'center' }}>
            {ExtraToolBar}
            {filtersTable && (
              <Tooltip title="Buscar registros">
                <IconButton onClick={onClick}>
                  <Search fontSize="large" />
                </IconButton>
              </Tooltip>
            )}
            {onCreate && (
              <CreateButton
                onClick={onCreate}
                requiredPermissions={createPermissions}
                disabled={disableCreateButton}
              />
            )}
            {onDownloadExcel
              && (
                <DownloadExcelButton
                  onClick={() => onDownloadExcel(search)}
                  requiredPermissions={updatePermissions}
                />
              )}
            {onDownloadPdf
              && (
                <DownloadPdfButton
                  onClick={onDownloadPdf}
                  requiredPermissions={updatePermissions}
                />
              )}
            {onSendExcel
              && (
                <SendExcelMailButton
                  onClick={onSendExcel}
                  requiredPermissions={sendEmailExcelPermissions}
                />
              )}
          </Grid>
        </Grid>
      </Toolbar>
      {show && (
        <Popover
          open
          anchorEl={anchorEl}
          onClose={onClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          transformOrigin={{ vertical: 'top', horizontal: 'center' }}
        >
          <Grid container direction="row" spacing={2} style={{ padding: 20, minWidth: 500 }}>
            <Typography color="inherit" variant="h4"> Buscar registros </Typography>
          </Grid>
          <Grid container direction="row" spacing={2} style={{ padding: 10, maxWidth: maxWidthFilter }}>
            <>
              {loading ? <Loader /> : (
                <Formik
                  innerRef={formik}
                  initialValues={initialValues}
                  enableReinitialize
                  onSubmit={(values) => {
                    handleSubmitFilter(values);
                    onClose();
                  }}
                >
                  {() => (
                    <Form>
                      {dates && (
                        <Grid container direction="row" justify="center" spacing={2}>
                          <Grid item xs={4} md={4} xl={4}>
                            <Field
                              name="dateInit"
                              label="Fecha inicio"
                              component={DatePickerBase}
                            />
                          </Grid>
                          <Grid item xs={4} md={4} xl={4}>
                            <Field
                              name="dateEnd"
                              label="Fecha fin"
                              component={DatePickerBase}
                            />
                          </Grid>
                        </Grid>
                      )}
                      <Grid
                        container
                        direction="row"
                        style={{ padding: 10, maxWidth: maxWidthFilter }}
                        spacing={2}
                      >
                        {columns.map(({
                          label, name, type = '', extra = {}, filter = true, filterMessage = null,
                        }, i) => (
                            <>
                              {filter ? (
                                <Grid
                                  key={`${String(i)}`}
                                  item
                                  xs={4}
                                  md={4}
                                  lg={4}
                                  xl={4}
                                  style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    alignItems: 'center',
                                    gap: 5,
                                  }}
                                >
                                  {getType(label, name, type, extra)}
                                  {Boolean(filterMessage) && <GeneralHelpCircle message={filterMessage} />}
                                </Grid>
                              ) : []}
                            </>
                          ))}
                      </Grid>
                    </Form>
                  )}
                </Formik>
              )}
            </>
          </Grid>
          <Grid container direction="row" spacing={2} style={{ padding: 10 }} justify="center">
            <Grid item xs={3} />
            <Grid item xs={2}>
              <ButtonGeneral
                onClick={() => {
                  setFilter({});
                  setSearch({});
                }}
                label="Reiniciar"
                color={purple[500]}
              />
            </Grid>
            <Grid item xs={2} />
            <Grid item xs={2}>
              <ButtonGeneral
                onClick={() => {
                  formik.current.submitForm();
                }}
                label="Buscar"
                color={green[500]}
              />
            </Grid>
            <Grid item xs={3} />
          </Grid>
        </Popover>
      )}
    </>
  );
}

ToolBar.defaultProps = {
  maxWidthFilter: 1200,
};

ToolBar.propTypes = {
  title: PropTypes.string,
  titleHelpMessage: PropTypes.string,
  dates: PropTypes.bool,
  ExtraToolBar: PropTypes.node,
  columns: PropTypes.oneOfType([PropTypes.array]),
  filters: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  setFilter: PropTypes.func,
  setGenericSearch: PropTypes.func,
  onCreate: PropTypes.func,
  onDownloadExcel: PropTypes.func,
  onDownloadPdf: PropTypes.func,
  createPermissions: PropTypes.oneOfType([PropTypes.array]),
  updatePermissions: PropTypes.oneOfType([PropTypes.array]),
  disableCreateButton: PropTypes.bool,
  filtersTable: PropTypes.bool,
  fechaCirugia: PropTypes.bool,
  onSendExcel: PropTypes.bool,
  maxWidthFilter: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  genericSearch: PropTypes.bool,
  sendEmailExcelPermissions: PropTypes.oneOfType([PropTypes.array]),
};

export default ToolBar;
