import React, { useState } from 'react';
import {
  Popover, Typography,
} from '@material-ui/core';
import { HelpCircle as HelpCircleIcon } from 'react-feather';
import { string } from 'prop-types';

export default function HelpCircle({ message }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  }

  const open = Boolean(anchorEl);

  return (
    <React.Fragment>
      <HelpCircleIcon
        aria-owns={open ? 'mouse-pop-over' : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        id="mouse-pop-over"
        open={open}
        style={{
          pointerEvents: 'none',
        }}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left'
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography variant="body1" style={{ margin: 5 }}>
          {message}
        </Typography>
      </Popover>
    </React.Fragment>
  );
}

HelpCircle.propTypes = {
  filterMessage: string,
}
